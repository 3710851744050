.header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
}

.nav-sticky .header_container {
    color: #000;
}

.dark .header_container {
    color: #fff;
}
.header_logo {
    display: block;
    width: fit-content;


}
.header_nav {
 display: flex;
 align-items: center;
 gap: 20px;
}
.header_nav-item {

}
.header_nav-item-link {
    text-transform: uppercase;
    font-size: 15px;

}

.header_nav-item-link:hover {
    color: rgb(6, 62, 191);
}

@media (max-width: 990px) {
    .header_container {
        color: #000;
    }
    .header_nav {
        gap: 15px;
    }
    .header_nav-item-link {
        font-size: 12px;
    
    }
}

@media (max-width: 768px) {
    .header_nav {
        display: none;
    }


}



.header_toggler {
    display: none;
  }
  .toggler-line {
    width: 100%;
    display: block;
    height: 2px;
    background-color: rgb(15 23 42);
  }

  .dark .toggler-line {
    background-color: #fff;
  }

  
  
  
  .open-menu {
      display: none;
  }
  .close-menu {
      display: none;
  }
.video_img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

  html {
    scroll-behavior: smooth;
  }
  


.stones__wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
}
.stone {
    width: 100%;
    text-align: center;
    
}
.stone_img {
    width: 80%;
    margin: 0 auto;
    height: 100px;
    object-fit: contain;
    object-position: center;
    transition: all 500ms ease;
}
.stone_content {
    padding: 5px;
}


.stone:hover .stone_img {
    transform: scale(1.2);
    
}

@media (max-width: 768px) {
    .video_img {
        /* width: 100%; */
        height: 300px;
    }
    .stones__wrapper {
        grid-template-columns: repeat(4, 1fr);
    }
    
    .header_nav {
      display: none;
    }
    .header_toggler {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 6px;
    }
  
    .open-menu {
      display: block;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: #0000006e;
      top: 0;
      left: 0;
      z-index: 50;
  }
  .close-menu {
      display: none;
  
  }
  .mobilenav {
      position: fixed;
      height: 100vh;
      max-width: 300px;
      width: 100%;
      left: 0;
      top: 0;
      padding: 20px 30px;
      background-color: #fff;
      z-index: 50;
      color: #000;
      overflow-y: auto;
  
  }

  .dark .mobilenav {
    background-color: rgb(15 23 42);
    color: #fff;
  }
  .mobilenav_logo {
      display: block;
      width: fit-content;
      margin: 10px auto;
  }
  .mobilenav_logo-img {
      width: 90px;
      height: 90px;
      object-fit: contain;
  }
  .mobilenav .header_nav {
      flex-direction: column;
      display: flex;
  }
  
  .toggler-wrapper {
      display: flex;
      justify-content: flex-end;
  }
  .toggler-wrapper .header_toggler {
      gap: 0;
  }
  .toggler-wrapper .header_toggler .toggler-right {
      transform: rotate(45deg);
  }
  .toggler-wrapper .header_toggler .toggler-left {
      margin-top: -2px;
      transform: rotate(-45deg);
  }
  }



  @media (max-width:530px) {
    .stones__wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width:450px) {
    .video_img {
        /* width: 100%; */
        height: 200px;
    }
    .stones__wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
  }


.pdf-card_wrapper {
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    display: block;
    font-size: 10px;
}

.pdf-card {
    max-width: 700px;
    width: 100%;
    

}
.pdf-card_top {
    padding: 15px 20px;
    display: flex;
    align-items: start;
    gap: 15px;
    border-bottom: 3px solid blue;

}
.pdf-card_logo {
    width: 70px;
    height: 70px;
    object-fit: contain;
    display: block;

}
.pdf-card_top-center {


}
.pdf-card_big-heading {
    text-transform: uppercase;
    font-size: 4.5em;
    line-height: 1;
    margin: 0;
    letter-spacing: 1.3;
    font-weight: 500;

}
.pdf-card_small-heading {
    text-transform: uppercase;
    font-size: 1.5em;
    letter-spacing: 1.1;
    margin: 0;

}
.pdf-card_top-right {
    margin-left: auto;

}
.pdf-card_identification-heading {
    text-transform: uppercase;
    font-size: 1.8em;
    font-weight: 600;


}
.pdf-card_identification {
    text-transform: uppercase;
    font-size: 1.4em;
    font-weight: 600;

}
.pdf-card_center-text {
    font-weight: 700;
    font-size: 2em;
    text-align: center;

}
.pdf-card_blue-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    background-color: blue;
    color: #fff;
    font-size: 2.2em;
    line-height: 1.2;
    font-weight: 600;
    font-family: serif;

}
.pdf-card_bottom {
    padding: 10px 20px;
    font-family: serif;
    display: flex;
    gap: 20px;

}
.pdf-card_bottom-left {
    
    width: 70%;
    display: flex;
    flex-direction: column;

}
.pdf-card_bottom-left-text-wrapper {
    display: grid;
    grid-template-columns: 45% 10% 45%  ;
    font-size: 1.8em;
    font-weight: 600;

}
.pdf-card_bottom-left-text {
    line-height: 1.2;
    font-size: 15px;
    margin: 0;

}
.pdf-card_weight-text {
    margin-top: 10px;
    line-height: 1;
    font-size: 1.8em;
    font-weight: 600;

}
.pdf-card_bottom-right {
    width: 30%;


}
.pdf-card_bottom-right-img {
    /* margin-top: 15px; */
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 5px auto;
}
.pdf-card_bottom-right-img-last {
    width: 130px;
    height: 130px;
    object-fit: contain;
    margin: 15px auto;
}
.pdf-card_authograf {
    width: 100%;
    height: 35px;
    border-bottom: 3px solid blue;
    margin-top: -25px;
}
.pdf-card_author {
    font-size: 2.2em;
    font-weight: 600;
    font-family: "Nunito";
}
.pdf-btn {
    margin: 30px auto;
    display: block;
    width: fit-content;
    padding: 8px 15px;
    border-radius: 6px;
    background: blue;
    color: #fff;
    font-weight: 600;
}

@media (max-width: 780px) {
    .pdf-card_logo {
        width: 50px;
        height: 50px;
    }
    .pdf-card_wrapper {
        font-size: 8px;
    }
    .pdf-card_bottom-right-img {
        width: 80px;
        height: 80px;
    }
}



@media (max-width: 550px) {
    .pdf-card_weight-text {
        margin-top: 5px;
    }
    .pdf-card_logo {
        width: 40px;
        height: 40px;
    }
    .pdf-card_wrapper {
        font-size: 6px;
    }
    .pdf-card_logo {
        width: 30px;
        height: 30px;
    }
    .pdf-card_wrapper {
        font-size: 5px;
    }
    .pdf-card_bottom {
        padding: 10px 10px;
    }
    .pdf-card_blue-text {
        padding: 5px 10px;
    }
    .pdf-card_authograf {
        height: 20px;
    }
    .pdf-card_bottom-right-img {
        width: 70px;
        height: 70px;
        margin: 10px auto;
    }
    .pdf-card_top {
        padding: 10px;

    }
}


.admin-page {
    padding-top: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.admin-logo {
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.admin-links {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    max-width: 400px;
    width: 100%;
}
.admin-link {
    display: block;
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    background-color: lightgrey;
    font-weight: 600;
    font-size: 16px;
    border-radius: 10px;
    transition: all 400ms ease;


}

.admin-link:hover {
    background-color: #0e2a80;
    color: #fff;
}

.admin-heading {
    font-size: 20px;
    font-weight: 500;
}


.admin-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0px 0px 8px;

}
.admin-header_container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;


}
.admin-header_logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
}
.admin-header_nav {
    display: flex;
    align-items: center;
    gap: 20px;


}
.admin-header_nav-link:hover {
    color: #0e2a80;
}

.layout_wrapper {
    padding-top: 90px;
}

.dashboard {

}
.dashboard_container {
    max-width: 1200px;
    width: 100%;
    padding: 10px 20px;
    margin: 0 auto;
}
.dashboard_link-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    gap: 20px;
}
.dashboard_heading {
    font-size: 27px;
    font-weight: 700;

}
.dashboard_link {
    padding: 10px 20px;
    color: #fff;
    background-color: #0e2a80;
    font-size: 16px;
    border-radius: 10px;

}

.dashboard_link-default {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 10px;

}
.dashboard_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
}

.dashboard-create {

}
.dashboard-create_container {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
}
.dashboard-create_content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.media-input_wrapper {
    display: flex;
    align-items: center;
    gap: 50px;
}
.media-input {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
}
.media-input_btn {
    padding: 10px 20px;
    color: #fff;
    background-color: #0e2a80;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
}
.media-input_img {
    display: block;
    width: 130px;
    height: 130px;
    border-radius: 15px;
    padding: 10px;
    object-fit: contain;
    border: 1px solid #000;

}

.dashboard-create_submit-btn {
    padding: 10px 20px;
    color: #fff;
    background-color: #0e2a80;
    font-size: 16px;
    border-radius: 10px;
}


.admin-stone_text-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin-stone_content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.admin-stone_img {
    width: 60px;
    height: 50px;
    object-fit: cove    r;
    border-radius: 12px;
    display: block;

}
.stones_table-wrapper {
    overflow-y: auto;
}
.stones_table {
    width: 1160px;
    font-size: 12px;
}
.admin-stone-table-row {
    border-bottom: 1px solid gray;
}
.stone-table-col {
    padding: 10px;
}
.stones_table-head-row {
    border-bottom: 1px solid gray;
    font-weight: 600;

}
.dashboard_search {
    background-color: rgb(231, 231, 231);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 15px;
    max-width: 250px;
    width: 100%;
    outline: none;
}
.admin-stone_img--big {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 15px;
}

.nav-toggler {
    display: none;
}
.toggler-line {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: #000;
}


@media (max-width: 1024px) {
    
    .dashboard_wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .nav-toggler {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 24px;
        height: 24px;
    }
    .admin-header_nav {
        display: none;
        
    }
    .opened {
        flex-direction: column;
        align-items: end;
    }
    .opened > button {
        order: 2;
    }
    .opened > a {
        order: 1;
        align-self: start;
    }
    .opened > nav {
        order: 3;
    }
    .open-admin-header {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: end;
    }
    .dashboard_link-wrapper {
        flex-direction: column;

    }
    .dashboard_wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (max-width: 400px) {
    
    .dashboard_wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}


    .pdf-container {
        max-width: 1260px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 20px;
    }

    